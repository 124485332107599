// HERO

section.hero {
  width: 100%;
  height: 95%;
  overflow: hidden;
  position: relative;

  .logo {
    width: 320px;
    height: 70px;
    margin-bottom: 48px;
  }

  .hero-no-slide {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-brand;
    display: block;
  }

  .hero-slider {
    position: absolute;
    overflow: hidden;
    background-position: center center;
    background-repeat: repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .slick-list,
  .slick-track,
  .slick-slide {
    height: 100%;
  }

  .slick-slide {
    > div {
      height: 100%;
      width: 100%;
      display: block;

      > div {
        height: 100%;
        width: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }      
    }
  }

  .slick-dots {
    @media (max-width: $width-medium) {
      display: none !important;
    }
  }

  h1 {
    text-shadow: 0 3px 20px rgba(0,0,0,0.2);
    display: block;
    padding: 0 0 20px 0;
    margin-bottom: 30px;

    &:after {
      content: '';
      width: 150px;
      height: 1px;
      position: absolute;
      background-color: $color-inverted;
      display: block;
      bottom: 0;
      right: 50%;
      margin-right: -75px;
      right: auto;
      left: 0;
      margin: 0;
      opacity: 0.5;
    }

    @media (max-width: $width-medium) {
      font-size: 60px;
      line-height: 50px;
    }
  }

  p {
    color: white;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 24px;
    list-style: disc;
    text-shadow: 0 3px 20px rgba(0,0,0,0.4);
  }

  ul {
    li {
      color: white;
      font-size: 20px;
      line-height: 24px;
      font-weight: 400;
      margin-bottom: 4px;
      list-style: disc;
      text-shadow: 0 3px 20px rgba(0,0,0,0.4);
    }
  }

  .container {
    height: 100%;

    .col-sm-12 {
      display: flex;
      flex-direction: column;
    }
  }

  .row {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .contact-form {
    .contact-form-wrapper {
      background-color: transparentize($color-brand, 0.1);
      color: $color-inverted;
      padding: 30px;
      border-radius: 6px;

      h3 {
        font-size: 36px;
        line-height: 32px;
        text-align: center;
        color: $color-inverted;
      }

      label {
        width: 100%;
        position: relative;

        .input-field,
        textarea,
        select {
          transition: $transition-general;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: none;
          background: none;
          box-shadow: none;
          outline: none;
          border-radius: 0;
          font-weight: 300;
          font-size: 16px;
          line-height: 30px;
          letter-spacing: -0.2px;
          width: 100%;
          margin-top: 15px;
          border: 1px solid $color-inverted;
          color: $color-inverted;
          padding: 10px 15px;
    
          &:focus {
            color: lighten($color-secondary, 10%);
            border-color: lighten($color-secondary, 10%);
          }
    
          &::-webkit-input-placeholder {
            color: $color-inverted;
          }
    
          &::-moz-placeholder {
            color: $color-inverted;
          }
    
          &:-ms-input-placeholder {
            color: $color-inverted;
          }
    
          &:-moz-placeholder {
            color: $color-inverted;
          }
        }
    
        textarea {
          height: 90px;
        }
      }
    
      .button {
        width: 100%;
        margin-top: 10px;
      }
    
      .error-message {
        font-weight: 300;
        font-size: 14px;
        font-style: italic;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: $color-inverted;
        border: 1px solid transparent;
    
        &.error {
          padding: 14px 15px;
          color: $color-error;
          border-color: $color-error;
        }
    
        &.success {
          padding: 14px 15px;
          color: $color-success;
          border-color: $color-success;
        }
    
        @media (max-width: $width-medium) {
          text-align: center;
    
          &.error {
            margin-bottom: 30px;
          }
    
          &.success {
            margin-bottom: 30px;
          }      
        }    
      }
    }
  }

  @media (max-width: 1320px) {
    height: 800px;
  }

  @media (max-width: $width-xlarge) {
    height: 800px;
  }

  @media (max-width: $width-large) {
    height: 800px;

    h1 {
      font-size: 48px;
      line-height: 40px;
    }
  }

  @media (max-width: $width-medium) {
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
    background-color: white;

    .logo {
      width: 240px;
      height: 52px;
      margin-bottom: 24px;
    }

    .hero-no-slide {
      position: relative;
      padding-bottom: 60%;      
      height: auto;
      margin: 0 0 40px;
    }

    .row {
      display: block;
    }

    h1 {
      font-size: 40px;
      line-height: 32px;
      width: 100%;
      margin: 0 0 24px 0;
      padding: 0;
      color: $color-secondary;
      text-shadow: none;

      &::after {
        content: none;
        display: none;
      }
    }

    p {
      font-size: 16px;
      margin-bottom: 16px;
      color: $color-brand;
      text-shadow: none;
    }

    ul {
      li {
        font-size: 16px;
        margin-bottom: 4px;
        color: $color-brand;
        text-shadow: none;
      }
    }

    .contact-form {
      .contact-form-wrapper {
        margin: 0 -15px;
        border-radius: 0;
        padding: 30px 15px 50px;
      }
    }  

    .slick-slide > div > div {
      background: white !important;
    }
  }
}
