// PROJECT STYLES 

@font-face {
  font-family: 'Teko';
	font-style: normal;
  font-weight: 300;
  src: url('../fonts/Teko-Light.ttf');
}

@font-face {
  font-family: 'Teko';
	font-style: normal;
  font-weight: 400;
  src: url('../fonts/Teko-Regular.ttf');
}

@font-face {
  font-family: 'Teko';
	font-style: normal;
  font-weight: 500;
  src: url('../fonts/Teko-Medium.ttf');
}

@font-face {
  font-family: 'Roboto';
	font-style: normal;
  font-weight: 300;
  src: url('../fonts/Roboto-Light.ttf');
}

@font-face {
  font-family: 'Roboto';
	font-style: normal;
  font-weight: 400;
  src: url('../fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto';
	font-style: italic;
  font-weight: 300;
  src: url('../fonts/Roboto-LightItalic.ttf');
}

@font-face {
  font-family: 'Roboto';
	font-style: italic;
  font-weight: 400;
  src: url('../fonts/Roboto-Italic.ttf');
}

@import 'reset';
@import 'variables';
@import 'helpers';
@import 'vendor';
@import 'main';
@import 'section.hero';
@import 'section.video';
@import 'section.company';
@import 'section.slider';
@import 'section.products';
@import 'section.footer';
@import 'section.quick-links';
@import 'section.whatsapp-link.scss';
