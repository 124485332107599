// PRODUCTS

section.products {
  background: #FFF;
  border-bottom: none;

  .flex-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-top: 40px;

    .flex-item {
      display: flex;
      margin-bottom: 20px;
    }
  }

  .flat-card {
    background-color: #FFFFFF;
    padding: 0;
    overflow: hidden;
    border: 2px solid $color-secondary;
    border-radius: 4px;
    position: relative;
    color: $color-inverted;
    margin-bottom: 30px;
    position: relative;

    .image-wrapper {
      width: 100%;
      height: 260px;
      overflow: hidden;
      position: relative;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    h3 {
      color: $color-brand;
      display: flex;
      width: 100%;
      text-align: center;
      padding: 0 15px;
      margin: 0;
      height: 94px;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }

  .button {
    background-color: $color-brand;
    color: #FFFFFF;
    bottom: 0;
    display: inline-block;

    @media (max-width: $width-small) {
      width: 100%;
    }    
  }

  @media (max-width: $width-xlarge) {
    .flat-card {
      .image-wrapper {
        height: 220px;
      }
    }
  }

  @media (max-width: $width-large) {
    .flat-card {
      .image-wrapper {
        height: 340px;
      }
    }
  }

  @media (max-width: $width-medium) {
    .flex-wrapper {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .flat-card {
      width: 160px;
      margin: 0 -10px 20px -10px;

      .image-wrapper {
        height: 160px;
      }

      h3 {
        height: 74px;
      }
    }
  }
}
