// WHATSAPP LINK

section.whatsapp-link {
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 2;
  width: auto;
  border: none;
  box-shadow: none;
  padding: 0;
  overflow: visible;
  cursor: pointer;

  .floating-circle {
    background-color: #06D755;
    box-shadow: 0 10px 20px rgba(0,0,0,0.4);
    border-radius: 50%;
    padding: 12px;
    display: inline-block;
    width: 32px;
    height: 32px;
    margin: 0;
    box-sizing: content-box;
  }

  .floating-icon {
    width: 32px;
    height: 32px;
    color: $color-inverted;
    fill: $color-inverted;
  }
}
