// SLIDER

section.fullscreen {
  width: 100%;
  height: 80%;
  overflow: hidden;
  position: relative;
  border-bottom: none;

  .fullscreen-slider {
    position: absolute;
    overflow: hidden;
    background-position: center center;
    background-repeat: repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .slick-list,
  .slick-track,
  .slick-slide {
    height: 100%;
  }

  .slick-slide {
    > div {
      height: 100%;
      width: 100%;
      display: block;

      > div {
        height: 100%;
        width: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        > .container {
          position: relative;
          z-index: 1;
        }

        &:after {
          background: 
            linear-gradient(transparentize($color-brand, 0.5) 0%, transparent 50%),
            linear-gradient(transparent 80%, transparentize(#FFFFFF, 0.2));
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;      
        }
      }
    }
  }

  .slick-dots {
    @media (max-width: $width-small) {
      // display: none !important;
    }
  }

  p {
    color: $color-inverted;
    opacity: 0.5;
  }

  h2 {
    color: $color-inverted;
    margin-top: 0;
  }

  h4 {
    color: $color-text;
    font-size: 32px;
    line-height: 32px;
  }

  .container {
    height: 100%;

    .col-sm-12 {
      display: flex;
      flex-direction: column;
    }
  }

  .row {
    height: 100%;
    display: flex;
    align-items: center;

    &.align-top {
      align-items: flex-start;
      padding-top: 60px;
    }
  }

  @media (max-width: $width-xlarge) {
    height: 700px;

    .row {
      &.align-top {
        padding-top: 50px;
      }
    }
  }

  @media (max-width: $width-large) {
    height: 500px;

    .row {
      &.align-top {
        padding-top: 50px;
      }
    }

    p {
      margin-bottom: 10px;
    }

    h4 {
      font-size: 24px;
      line-height: 24px;
    }
  }

  @media (max-width: $width-medium) {
    height: 800px;

    .slick-slide {
      > div {
        > div {
          background-position: bottom center;
        }
      }
    }

    .row {
      &.align-top {
        padding-top: 50px;
      }
    }

    p {
      margin-bottom: 10px;
    }

    h4 {
      font-size: 24px;
      line-height: 24px;
    }
  }

  @media (max-width: 630px) {
    height: 600px;
  }

  @media (max-width: $width-small) {
    height: 480px;
  }
}
