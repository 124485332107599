// COMPANY

section.company {
  .company-slider {
    .slick-slide {
      border: 2px solid #E0E0E0;
      border-radius: 6px;
      overflow: hidden;

      > div {
        &:after {
          background: linear-gradient(transparent 70%, transparentize(#FFFFFF, 0.1));
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;      
        }        
      }
    }
  }

  @media (max-width: $width-medium) {
    padding-top: 50px;
    padding-bottom: 50px;
    box-shadow: none;
  }

  .company-slider {
    @media (max-width: $width-medium) {
      margin-top: 30px;
    }
  }

  a.button {
    margin: 10px 0 10px 0;
    background-color: $color-secondary;
    color: $color-inverted;

    @media (max-width: $width-small) {
      width: 100%;
      text-align: center;
    }
  }
}
