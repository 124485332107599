// FOOTER

section.footer {
  padding-bottom: 120px;
  box-shadow: none;

  a,
  a:link,
  a:active,
  a:visited {
    color: $color-text;
    font-weight: 400;

    &:hover,
    &:focus {
      color: lighten($color-secondary, 20%);
    }
  }

  img {
    border: 2px solid #E0E0E0;
    border-radius: 6px;
    overflow: hidden;
    width: 100%;
  }

  .footer-circle {
    transition: all 0.2s ease-in-out;
    background-color: $color-brand;
    border-radius: 50%;
    padding: 12px;
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 0 8px 0 0;
    box-sizing: content-box;
    margin-bottom: 40px;
    cursor: pointer;

    &:hover {
      background-color: $color-secondary;
    }
  }

  .footer-icon {
    width: 24px;
    height: 24px;
    color: $color-inverted;
    fill: $color-inverted;
  }

  @media (max-width: $width-large) {
    padding: 40px 0 100px;
  }
}
